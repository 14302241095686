.d-block {
    display: block;
}

.d-flex {
    display: flex;
}

.d-none {
    display: none;
}

.opacity-0 {
    opacity: 0;
}

.flex-col {
    flex-direction: column;
}

.items-ctr {
    align-items: center;
}

.justify-ctr {
    justify-content: center;
}

.flex-ctr {
    display: flex;
    justify-content: center;
    align-items: center;
}

.pos-abs {
    position: absolute;
}

.pos-rel {
    position: relative;
}

.desktop-only {
    display: none;
}

.txt-lg {
    font-size: 18px;
    line-height: 1.6em;
}

.txt-md {
    font-size: 16px;
    line-height: 1.6em;
}

.txt-sm {
    font-size: 14px;
}

.txt-ctr {
    text-align: center;
}

.underline {
    text-decoration: underline;
}

.lowercase {
    text-transform: none;
}

.fw-bold {
    font-weight: 700;
}

.blue {
    color: var(--fm-blue);
}

.lightblue {
    color: var(--fm-lightblue);
}

.bg-blue {
    background-color: var(--fm-blue);
}

.bg-lightblue {
    background-color: var(--fm-lightblue);
}

.white {
    color: #ffffff;
}

.grey {
    color: #666;
}

.md-red {
    color: var(--md-red);
}

.ls-blue {
    color: var(--ls-blue);
}
  
.w-100 {
    width: 100% !important;
}
  
.h-100 {
    height: 100% !important;
}

.soft-shadow {
    box-shadow: 0 0 10px rgb(0 0 0 / 0.2);
}

.bg-md-red {
    background-color: var(--md-red);
}

.bg-fixed {
    background-attachment: fixed;
}

.bg-cover {
    background-size: cover;
}

.bg-contain {
    background-size: contain;
}

.bg-no-repeat {
    background-repeat: no-repeat;
}

.bg-ctr {
    background-position: center center;
}

.bbox {
    box-sizing: border-box;
}

.pointer {
    cursor: pointer;
}

.rounded-sm {
    border-radius: 4px;
}

.rounded-md {
    border-radius: 7px;
}

.m-0 {
    margin: 0 !important;
}

.mx-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
}

.my-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
}

.ml-0 {
    margin-left: 0 !important;
}

.mr-0 {
    margin-right: 0 !important;
}

.mt-0 {
    margin-top: 0 !important;
}

.mb-0 {
    margin-bottom: 0 !important;
}

.m-1 {
    margin: 0.5rem !important;
}

.mx-1 {
    margin-left: 0.5rem !important;
    margin-right: 0.5rem !important;
}

.my-1 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
}

.ml-1 {
    margin-left: 0.5rem !important;
}

.mr-1 {
    margin-right: 0.5rem !important;
}

.mt-1 {
    margin-top: 0.5rem !important;
}

.mb-1 {
    margin-bottom: 0.5rem !important;
}

@media screen and (min-width: 768px) {
    .desktop-only {
        display: initial;
    }

    .mobile-only {
        display: none;
    }
}