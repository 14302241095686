:root {
  --fm-blue: #005598;
  --fm-lightblue: #6fb9e1;
  --fm-blue-trans: #005598bb;
  --fm-lightblue-trans: #6fb9e1bb;
  --ls-blue: #00a3e0;
  --md-red: #da291c;
}

html {
  scroll-behavior: smooth;
}

body {
  color: #666;
}

.nav-main {
  margin-left: 4%;
}

div[data-id="SHL753"] .prices {
  display: none;
}

.overlay {
  opacity: 0;
}

h1, h2, h3, h4, h5, h6, a.font-h4, .nav-item-root .hyp-top-lvl {
  letter-spacing: 1px;
  word-break: keep-all;
}

.nav-item-root .hyp-top-lvl {
  padding: 0 14px;
}

.h1-holder {
  border-bottom: 5px solid var(--fm-lightblue);
  text-align: center;
  border-radius: 4px;
  margin: 10px 0 0;
  padding: 10px 5px 5px;
  background-color: var(--fm-blue) !important;
}

.h1-holder h1 {
  font-size: 1.5em;
  font-weight: 700;
}

.h1-holder h1, .h1-holder span {
  color: #fff;
}

.heading-underline, .heading-lowercase {
  text-transform: none;
  font-weight: 700;
  line-height: 1.3em;
}

.heading-lowercase {
  margin: 1em 0 1.5em;
}

.heading-underline {
  margin: 30px 0 10px;
}

.heading-underline:after {
  background-color: var(--fm-lightblue);
  content: "";
  width: 30px;
  height: 4px;
  margin: 4px 0 0 1px;
  display: block;
}

.block-padding {
  padding-top: 60px;
  padding-bottom: 60px;
}

.block-anchor {
  margin-top: -60px;
  position: absolute;
}

a.hyp-top-lvl {
  font-weight: 700;
}

a:focus {
  outline: none;
}

a.no-decoration:hover {
  text-decoration: none;
}

.site .content {
  padding-bottom: 50px;
}

.bg-img {
  z-index: 0;
  background-position: center;
  background-size: cover;
  transition: transform .4s;
}

.overlay {
  z-index: 1;
  transition: opacity .3s;
}

.bg-img, .overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
}

.wrap {
  position: relative;
  overflow: hidden;
}

.wrap:hover .bg-img {
  transform: scale(1.1);
}

.wrap .cta-link {
  margin: 0 auto;
  top: 50%;
}

.wrap:hover .cta-link {
  opacity: .9;
  margin-top: -30px;
}

.wrap:hover .overlay {
  opacity: .2;
}

.bg-grey-rounded {
  background-image: linear-gradient(135deg, #dfdfdf, #fafafa);
  border-radius: 25px;
  padding: 25px;
}

.bg-grey-gradient {
  background-image: linear-gradient(135deg, #ddd, #fafafa, #fff, #fefefe, #ddd);
  background-size: cover;
  background-attachment: fixed;
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
}

.infographic {
  border-radius: 20px;
  max-width: 500px;
  margin: 50px auto;
  display: block;
  box-shadow: 5px 5px 25px #ccc;
}

.video-embed {
  width: 100%;
  height: 200px;
  margin: 20px auto;
}

.gmap-embed {
  box-sizing: border-box;
  border: 5px solid #aadaff;
  border-left: 5px solid var(--fm-lightblue);
  width: 100%;
  height: 400px;
  margin-top: 20px;
}

.mobile-frame {
  box-sizing: border-box;
  border: 15px solid #333;
  border-radius: 40px;
}

.page-padding-bottom {
  padding-bottom: 50px;
}

.cta-link {
  color: var(--fm-blue);
  border: 2px solid var(--fm-blue);
  text-transform: uppercase;
  letter-spacing: 1px;
  background-color: #fff;
  border-radius: 20px;
  width: fit-content;
  padding: 4px 12px;
  font-size: 14px;
  font-weight: 700;
  transition: all .3s;
  display: block;
}

.cta-link:hover {
  color: #fff;
  background-color: var(--fm-blue);
  text-decoration: none;
}

.cta-link i {
  margin-right: 10px;
}

.cta-link-red {
  border-color: var(--md-red);
  color: var(--md-red);
}

.cta-link-red:hover {
  background-color: var(--md-red);
}

.link-box {
  padding: 5px;
  display: block;
  box-shadow: 0 0 10px #0003;
  text-decoration: none !important;
}

.link-box:hover .bg-img {
  transform: scale(1.1);
}

.lb-container {
  height: 400px;
  position: relative;
  overflow: hidden;
}

.lb-container h3 {
  z-index: 1;
  color: #fff;
  background-color: var(--fm-blue-trans);
  box-sizing: border-box;
  text-align: center;
  border-top: 5px solid var(--fm-lightblue-trans);
  width: 100%;
  padding: 10px;
  font-size: 22px;
  position: absolute;
  bottom: 0;
}

.lb-container .fa-sign-in-alt, .lb-container .fa-angle-double-right {
  opacity: 0;
  color: #fff;
  text-shadow: 2px 2px 5px #0000004d;
  margin-top: 15px;
  font-size: 90px;
  transition: all .4s;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.lb-container:hover .fa-sign-in-alt, .lb-container:hover .fa-angle-double-right {
  opacity: 1;
  margin-top: -15px;
}

.lb-container .lb-intro {
  box-sizing: border-box;
  opacity: 0;
  z-index: 2;
  text-align: center;
  color: #fff;
  background-color: var(--fm-blue-trans);
  border-top: 1px solid #fff;
  width: 100%;
  margin: 0 auto;
  padding: 20px 20px 20px 30px;
  font-size: 15px;
  transition: all .4s;
  position: absolute;
  bottom: -100px;
}

.lb-container:hover .lb-intro {
  opacity: 1;
  bottom: 0;
}

.lb-more {
  margin-top: 10px;
  display: block;
}

.lb-more span {
  color: var(--fm-blue);
  background-color: #ffffffb3;
  border-radius: 3px;
  padding: 6px 8px;
  transition: all .4s;
  display: inline-block;
}

.lb-intro:hover .lb-more span {
  background-color: #fff;
}

.lb-container .overlay {
  background-image: linear-gradient(to top, #0000, #fff);
}

.lb-container img {
  z-index: 2;
  margin-left: 50%;
  transition: all .4s;
  position: absolute;
  top: 20px;
  transform: translate(-50%);
}

.lb-container:hover img {
  top: 30px;
}

.on-top {
  z-index: 2;
}

.page-banner-container {
  background-color: #ddd;
  background-size: cover;
  background-attachment: fixed;
  flex-flow: row;
  place-content: stretch center;
  align-items: center;
  height: 250px;
  display: flex;
  position: relative;
}

.fa-ul {
  line-height: 2.2em;
}

.fa-ul .fa-li {
  color: #0eae0e;
  margin-right: 10px;
  font-size: 24px;
}

.btn-app img {
  width: 200px;
  max-width: 45%;
  margin-left: 10px;
}

.site {
  overflow: hidden;
}

.body-text {
  font-size: 16px;
  line-height: 1.6em;
}

.info-block {
  border-left: 5px solid var(--fm-lightblue);
  background-color: #f8f8f8;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  align-items: center;
  margin-top: 20px;
  padding: 20px;
  display: flex;
}

.info-block-text h2 {
  text-transform: unset;
  color: var(--fm-blue);
  margin: 5px 0 10px;
  font-size: 24px;
  font-weight: 700;
}

.info-block i {
  color: #0069aa40;
  justify-content: center;
  align-items: center;
  margin: 0 0 0 15px;
  font-size: 70px;
  display: none;
}

.info-block p {
  margin: 0;
  font-size: 16px;
}

.page-heading {
  background-color: var(--fm-blue);
  text-align: center;
  color: #fff;
  border-bottom: 5px solid var(--fm-lightblue);
  border-radius: 4px;
  margin-top: 20px;
  padding: 10px;
  font-weight: 600;
}

.page-heading i {
  margin-right: 10px;
}

.top-dropdown.language .hyp {
  border-left: 5px solid #0000;
  padding: 0 35px 0 15px;
  display: flex;
}

.top-dropdown.language .hyp.selected {
  border-left-color: #6fb9e155;
}

.language.top-dropdown .summary {
  padding-top: 0;
}

.top-dropdown.language .opener .flag {
  left: 10px;
}

.curved-banner .banner-curve {
  width: 100%;
  position: absolute;
  bottom: -2px;
}

.curved-banner .banner-curve:nth-last-child(2) {
  opacity: .25;
  bottom: 10px;
}

.img-resp {
  box-sizing: border-box;
  width: 100%;
  max-width: 100%;
  max-height: 100%;
}

[data-aos] {
  animation-duration: 1.2s;
  animation-timing-function: ease;
}

[data-aos="fade-up"] {
  animation-name: fade-up;
}

[data-aos="fade-down"] {
  animation-name: fade-down;
}

[data-aos="flip-up"] {
  backface-visibility: hidden;
  animation-name: flip-up;
}

@keyframes fade-up {
  0% {
    opacity: 0;
    transform: translate3d(0, 100px, 0);
  }

  33% {
    opacity: 0;
    transform: translate3d(0, 100px, 0);
  }

  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fade-down {
  0% {
    opacity: 0;
    transform: translate3d(0, -100px, 0);
  }

  33% {
    opacity: 0;
    transform: translate3d(0, -100px, 0);
  }

  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes flip-up {
  0% {
    opacity: 0;
    transform: perspective(2500px)rotateX(-100deg);
  }

  33% {
    opacity: 0;
    transform: perspective(2500px)rotateX(-100deg);
  }

  100% {
    opacity: 1;
    transform: perspective(2500px)rotateX(0);
  }
}

@media (width >= 390px) {
  .curved-banner .banner-curve:nth-last-child(2) {
    bottom: 15px;
  }
}

@media (width >= 450px) {
  .video-embed {
    height: 300px;
  }
}

@media (width >= 500px) {
  .page-banner-container {
    height: 350px;
  }
}

@media (width >= 576px) {
  .curved-banner .banner-curve:nth-last-child(2) {
    bottom: 20px;
  }
}

@media screen and (width >= 768px) {
  .video-embed {
    height: 400px;
  }

  .body-text, .info-block p {
    font-size: 18px;
  }

  .info-block i {
    display: flex;
  }

  .gmap-embed {
    height: 500px;
  }

  .cta-link {
    padding: 6px 18px;
    font-size: 16px;
  }

  .flipbook {
    height: 550px;
  }
}

@media screen and (width >= 992px) {
  .page-banner-container {
    height: 550px;
  }

  .video-embed {
    height: 560px;
  }

  .gmap-embed {
    height: 650px;
  }

  .curved-banner .banner-curve:nth-last-child(2) {
    bottom: 40px;
  }

  .flipbook {
    height: 750px;
  }
}

@media (width >= 1024px) {
  .nav-item-root .hyp-top-lvl {
    padding: 0 10px;
  }
}

@media (width >= 1200px) {
  .nav-item-root .hyp-top-lvl {
    padding: 0 13px;
  }
}

.d-block {
  display: block;
}

.d-flex {
  display: flex;
}

.d-none {
  display: none;
}

.opacity-0 {
  opacity: 0;
}

.flex-col {
  flex-direction: column;
}

.items-ctr {
  align-items: center;
}

.justify-ctr {
  justify-content: center;
}

.flex-ctr {
  justify-content: center;
  align-items: center;
  display: flex;
}

.pos-abs {
  position: absolute;
}

.pos-rel {
  position: relative;
}

.desktop-only {
  display: none;
}

.txt-lg {
  font-size: 18px;
  line-height: 1.6em;
}

.txt-md {
  font-size: 16px;
  line-height: 1.6em;
}

.txt-sm {
  font-size: 14px;
}

.txt-ctr {
  text-align: center;
}

.underline {
  text-decoration: underline;
}

.lowercase {
  text-transform: none;
}

.fw-bold {
  font-weight: 700;
}

.blue {
  color: var(--fm-blue);
}

.lightblue {
  color: var(--fm-lightblue);
}

.bg-blue {
  background-color: var(--fm-blue);
}

.bg-lightblue {
  background-color: var(--fm-lightblue);
}

.white {
  color: #fff;
}

.grey {
  color: #666;
}

.md-red {
  color: var(--md-red);
}

.ls-blue {
  color: var(--ls-blue);
}

.w-100 {
  width: 100% !important;
}

.h-100 {
  height: 100% !important;
}

.soft-shadow {
  box-shadow: 0 0 10px #0003;
}

.bg-md-red {
  background-color: var(--md-red);
}

.bg-fixed {
  background-attachment: fixed;
}

.bg-cover {
  background-size: cover;
}

.bg-contain {
  background-size: contain;
}

.bg-no-repeat {
  background-repeat: no-repeat;
}

.bg-ctr {
  background-position: center;
}

.bbox {
  box-sizing: border-box;
}

.pointer {
  cursor: pointer;
}

.rounded-sm {
  border-radius: 4px;
}

.rounded-md {
  border-radius: 7px;
}

.m-0 {
  margin: 0 !important;
}

.mx-0 {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.ml-0 {
  margin-left: 0 !important;
}

.mr-0 {
  margin-right: 0 !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.m-1 {
  margin: .5rem !important;
}

.mx-1 {
  margin-left: .5rem !important;
  margin-right: .5rem !important;
}

.my-1 {
  margin-top: .5rem !important;
  margin-bottom: .5rem !important;
}

.ml-1 {
  margin-left: .5rem !important;
}

.mr-1 {
  margin-right: .5rem !important;
}

.mt-1 {
  margin-top: .5rem !important;
}

.mb-1 {
  margin-bottom: .5rem !important;
}

@media screen and (width >= 768px) {
  .desktop-only {
    display: initial;
  }

  .mobile-only {
    display: none;
  }
}

#uk-about-txt {
  flex-wrap: wrap;
  font-size: 16px;
  display: flex;
}

#uk-about-txt-img {
  flex: 0 0 100%;
}

#about-header {
  border-bottom: 10px solid var(--fm-lightblue);
  background-color: #eee;
  width: 100%;
  height: 250px;
  position: relative;
  overflow: hidden;
}

#about-header .overlay {
  opacity: 1;
  clip-path: polygon(0% 0%, 80% 0%, 100% 100%, 0% 100%);
  background-image: linear-gradient(45deg, #005598, #6fb9e130);
  width: 100%;
}

#about-header .fleming-f {
  opacity: .2;
  z-index: 2;
  height: 110%;
  position: absolute;
  left: 10%;
}

#about-header .center {
  z-index: 2;
  height: 100%;
  position: relative;
}

#about-header .center h1 {
  color: #fff;
  text-transform: none;
  font-size: 60px;
  position: absolute;
  bottom: 10px;
}

.link-target {
  position: relative;
  top: -80px;
}

.info-contents {
  box-sizing: border-box;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 30px 0;
  display: flex;
}

.info-contents ul {
  box-sizing: border-box;
  margin-bottom: 30px;
  padding-inline-start: 20px;
  font-size: 16px;
}

#productImageMap {
  flex-basis: 100%;
  position: relative;
}

#productImageMap img {
  width: 100%;
}

#productImageMap a {
  box-sizing: border-box;
  background-color: #0000;
  border-radius: 5px;
  height: 85%;
  transition: background-color .3s;
  position: absolute;
  top: 0;
}

#productImageMap a:hover {
  background-color: #6fb9e115;
}

#productImageMap a:first-child {
  width: 33%;
  left: 0;
}

#productImageMap a:nth-child(2) {
  width: 20%;
  left: 33.1%;
}

#productImageMap a:nth-child(3) {
  width: 27%;
  left: 53.2%;
}

#productImageMap a:nth-child(4) {
  width: 10%;
  left: 80.3%;
}

#productImageMap a:nth-child(5) {
  width: 9%;
  left: 90.4%;
}

.core-values {
  flex-wrap: wrap;
  justify-content: center;
  display: flex;
}

.core-values .value {
  box-sizing: border-box;
  text-align: center;
  background-image: url("https://www.flemingmedical.ie/content/files/images/banner-images/vector-bg-img-grey-v2.jpg");
  background-position: 50%;
  background-attachment: fixed;
  border-bottom: 5px solid #6fb9e1;
  border-radius: 5px;
  flex-direction: column;
  flex-basis: 98%;
  align-content: center;
  min-height: 280px;
  margin: 30px 0;
  padding: 25px;
  display: flex;
}

.core-values .value i {
  color: #005598;
  background-color: #fff;
  border: 1px solid #eee;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 80px;
  margin: -65px 50% 20px;
  font-size: 30px;
  display: flex;
  transform: translate(-50%);
}

.core-values .value h3 {
  color: #005598;
  font-weight: 700;
}

.core-values .value p {
  font-size: 16px;
}

.core-values .value ul {
  text-align: left;
  padding-inline-start: 20px;
  font-size: 16px;
}

#our-story p {
  font-size: 16px;
}

#our-story .link-box {
  box-sizing: border-box;
  width: 100%;
  margin-bottom: 20px;
}

#fleming-brand-family {
  justify-content: center;
  margin: 20px 0;
  display: flex;
}

#fleming-brand-family svg {
  max-width: 800px;
}

#fleming-brand-family svg .arrow {
  animation: 3s infinite arrow-down;
}

#fleming-brand-family svg .circles {
  animation: 3s infinite slow-pulse;
}

@keyframes arrow-down {
  0% {
    transform: translateY(0);
  }

  66% {
    transform: translateY(2px);
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes slow-pulse {
  0% {
    opacity: .4;
  }

  66% {
    opacity: 1;
  }

  100% {
    opacity: .4;
  }
}

@media (width >= 576px) {
  .header-full .overlay {
    width: 65%;
  }

  .core-values {
    justify-content: space-around;
  }

  .core-values .value {
    flex-basis: 47%;
  }
}

@media (width >= 768px) {
  #uk-about-txt {
    flex-wrap: nowrap;
  }

  #uk-about-txt-img {
    flex: 0 0 45%;
  }

  #about-header .overlay {
    width: 45%;
  }

  .info-contents ul {
    flex-basis: 25%;
  }

  #productImageMap {
    flex-basis: 74%;
  }
}

@media (width >= 992px) {
  .core-values {
    justify-content: space-between;
  }

  .core-values .value {
    flex-basis: 32%;
  }

  #our-story .link-box {
    float: right;
    width: 600px;
    margin: 5px 0 20px 20px;
  }
}

.categories {
  flex-flow: wrap;
  justify-content: space-between;
  margin-top: 20px;
  display: flex;
}

.top-category-link {
  flex-basis: 100%;
  height: 260px;
  margin-bottom: 20px;
  position: relative;
  overflow: hidden;
}

.top-category-link .wrap {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
}

.top-category-link h2 {
  color: var(--fm-blue);
  border-bottom: 5px solid var(--fm-lightblue);
  text-align: center;
  letter-spacing: unset;
  padding: 10px 0;
  font-size: 18px;
  font-weight: 600;
  transition: all .4s;
}

.top-category-link .fa-search {
  color: #fff;
  font-size: 30px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -70%);
}

.top-category-link .overlay {
  background-color: var(--fm-blue);
}

.top-category-link .fa-search, .top-category-link .overlay {
  opacity: 0;
  transition: all .4s;
}

.top-category-link:hover, .top-category-link:hover h2 {
  text-decoration: none;
}

.top-category-link:hover .bg-img {
  transform: scale(1.1);
}

.top-category-link:hover .fa-search {
  opacity: 1;
  margin-top: -20px;
}

.top-category-link:hover .overlay {
  opacity: .25;
}

.category-link {
  flex-basis: 100%;
  margin: 8px 0;
  transition: all .25s;
  position: relative;
  overflow: hidden;
}

.category-link img {
  transition: transform .25s;
}

.category-hover-btn {
  box-sizing: border-box;
  background-color: var(--fm-blue);
  border-left: 5px solid var(--fm-lightblue);
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 30px;
  transition: transform .25s;
  display: flex;
  position: absolute;
  bottom: 8px;
  right: -60px;
}

.border-effect {
  background-color: var(--fm-lightblue);
  width: 100%;
  height: 5px;
  transition: transform .25s;
  position: absolute;
  bottom: 8px;
  left: -100%;
}

.category-hover-btn i {
  color: #fff;
  opacity: 0;
  font-size: 20px;
  transition: all .25s;
  transform: translate(-150px);
}

.category-link:hover img {
  transform: translate(0, -4px);
}

.category-link:hover .category-hover-btn {
  transform: translate(-60px);
}

.category-link:hover .category-hover-btn i {
  opacity: 1;
  transform: translate(0);
}

.category-link:hover .border-effect {
  transform: translate(100%);
}

@media screen and (width >= 550px) {
  .top-category-link {
    flex-basis: 48%;
  }

  .category-link {
    flex-basis: 49%;
  }
}

@media screen and (width >= 768px) {
  .category-link {
    flex-basis: 32%;
  }

  .top-category-link .long-heading {
    font-size: 17px;
  }
}

@media screen and (width >= 992px) {
  .top-category-link {
    flex-basis: 32.5%;
    height: 250px;
  }
}

.site {
  padding-bottom: 0 !important;
}

footer {
  background: linear-gradient(to bottom, var(--fm-blue), #0069aae3);
  font-size: 14px;
}

footer .bottom {
  padding-bottom: 30px;
}

footer .font-h4, .column.newsletter div {
  color: #fff;
}

#stockists .flex-ctr {
  justify-content: flex-start;
}

#stockists .cta-link {
  color: var(--fm-lightblue);
  border: none;
  margin-left: 20px;
  padding: 4px 16px;
}

#stockists .cta-link:hover {
  color: #fff;
}

#stockists .cta-link .fa-map-marked-alt {
  font-size: 18px;
}

.noclick {
  pointer-events: none;
}

.newsletter form {
  display: none;
}

.social-icons {
  flex-flow: row;
  justify-content: left;
  margin-bottom: 12px;
  display: flex;
}

.social-icons a:hover {
  text-decoration: none;
}

.social-icons span {
  width: 120px;
  margin-top: 6px;
  display: inline-block;
}

.social-icons i {
  color: #fff;
  background-color: #fff3;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  width: 35px;
  height: 35px;
  margin: 0 12px 0 0;
  font-size: 18px;
  transition: all .4s;
  display: flex;
}

.social-icons i:hover {
  color: var(--fm-blue);
  background-color: #fff;
}

.engagebay-forms {
  border: 1px solid #eee;
  border-left: 5px solid var(--fm-lightblue);
  background-size: cover;
  background-attachment: fixed;
  justify-content: center;
  display: flex;
  padding: 0 !important;
}

.engagebay-forms .form {
  box-shadow: none;
  background-color: #0000;
  width: 500px;
  margin: 0 !important;
  padding: 0 !important;
  font-family: Nunito Sans !important;
}

.engagebay-forms .form .form-content {
  padding: 20px;
}

.engagebay-forms h2 {
  font-family: Nunito Sans !important;
}

.eb-field-required-error {
  color: red;
}

.engagebay-forms .form-group .control-label {
  padding: 0 !important;
  font-weight: 700 !important;
  display: inline !important;
}

.engagebay-forms select.form-control {
  height: 37px;
}

.engagebay-forms .form .form-group {
  word-break: keep-all;
  margin-bottom: 20px !important;
}

.engagebay-forms .form .submit-btn {
  color: #fff;
  background-color: var(--fm-blue);
  border: none;
  border-radius: 5px;
  margin-top: 20px;
  padding: 10px 15px;
}

.engagebay-forms .form .submit-btn:hover {
  background-color: var(--fm-blue-trans);
}

.ie-site .engagebay-messenger-frame {
  animation: 5s chatFade;
}

@keyframes chatFade {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.uk-site .engagebay-messenger-frame {
  display: none;
}

.rw {
  flex-wrap: wrap;
  display: flex;
}

.rw, .cl, [class*="cl-"], [class*="rw-"] {
  box-sizing: border-box;
}

.cl, [class*="cl-"] {
  padding: 5px;
}

.cl-1, .cl-2, .cl-3, .cl-4, .cl-5, .cl-6, .cl-7, .cl-8, .cl-9, .cl-10, .cl-11, .cl-12, .cl, .cl-auto, .cl-sm-1, .cl-sm-2, .cl-sm-3, .cl-sm-4, .cl-sm-5, .cl-sm-6, .cl-sm-7, .cl-sm-8, .cl-sm-9, .cl-sm-10, .cl-sm-11, .cl-sm-12, .cl-sm, .cl-sm-auto, .cl-md-1, .cl-md-2, .cl-md-3, .cl-md-4, .cl-md-5, .cl-md-6, .cl-md-7, .cl-md-8, .cl-md-9, .cl-md-10, .cl-md-11, .cl-md-12, .cl-md, .cl-md-auto, .cl-lg-1, .cl-lg-2, .cl-lg-3, .cl-lg-4, .cl-lg-5, .cl-lg-6, .cl-lg-7, .cl-lg-8, .cl-lg-9, .cl-lg-10, .cl-lg-11, .cl-lg-12, .cl-lg, .cl-lg-auto, .cl-xl-1, .cl-xl-2, .cl-xl-3, .cl-xl-4, .cl-xl-5, .cl-xl-6, .cl-xl-7, .cl-xl-8, .cl-xl-9, .cl-xl-10, .cl-xl-11, .cl-xl-12, .cl-xl, .cl-xl-auto {
  width: 100%;
  position: relative;
}

.cl {
  flex-grow: 1;
  flex-basis: 0;
  max-width: 100%;
}

.cl-auto {
  flex: none;
  width: auto;
  max-width: 100%;
}

.cl-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%;
}

.cl-2 {
  flex: 0 0 16.6667%;
  max-width: 16.6667%;
}

.cl-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.cl-4 {
  flex: 0 0 33.3333%;
  max-width: 33.3333%;
}

.cl-5 {
  flex: 0 0 41.6667%;
  max-width: 41.6667%;
}

.cl-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.cl-7 {
  flex: 0 0 58.3333%;
  max-width: 58.3333%;
}

.cl-8 {
  flex: 0 0 66.6667%;
  max-width: 66.6667%;
}

.cl-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.cl-10 {
  flex: 0 0 83.3333%;
  max-width: 83.3333%;
}

.cl-11 {
  flex: 0 0 91.6667%;
  max-width: 91.6667%;
}

.cl-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.offset-1 {
  margin-left: 8.33333%;
}

.offset-2 {
  margin-left: 16.6667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.3333%;
}

.offset-5 {
  margin-left: 41.6667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.3333%;
}

.offset-8 {
  margin-left: 66.6667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.3333%;
}

.offset-11 {
  margin-left: 91.6667%;
}

@media (width >= 576px) {
  .cl-sm {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
  }

  .cl-sm-auto {
    flex: none;
    width: auto;
    max-width: 100%;
  }

  .cl-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }

  .cl-sm-2 {
    flex: 0 0 16.6667%;
    max-width: 16.6667%;
  }

  .cl-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .cl-sm-4 {
    flex: 0 0 33.3333%;
    max-width: 33.3333%;
  }

  .cl-sm-5 {
    flex: 0 0 41.6667%;
    max-width: 41.6667%;
  }

  .cl-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .cl-sm-7 {
    flex: 0 0 58.3333%;
    max-width: 58.3333%;
  }

  .cl-sm-8 {
    flex: 0 0 66.6667%;
    max-width: 66.6667%;
  }

  .cl-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .cl-sm-10 {
    flex: 0 0 83.3333%;
    max-width: 83.3333%;
  }

  .cl-sm-11 {
    flex: 0 0 91.6667%;
    max-width: 91.6667%;
  }

  .cl-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .offset-sm-0 {
    margin-left: 0;
  }

  .offset-sm-1 {
    margin-left: 8.33333%;
  }

  .offset-sm-2 {
    margin-left: 16.6667%;
  }

  .offset-sm-3 {
    margin-left: 25%;
  }

  .offset-sm-4 {
    margin-left: 33.3333%;
  }

  .offset-sm-5 {
    margin-left: 41.6667%;
  }

  .offset-sm-6 {
    margin-left: 50%;
  }

  .offset-sm-7 {
    margin-left: 58.3333%;
  }

  .offset-sm-8 {
    margin-left: 66.6667%;
  }

  .offset-sm-9 {
    margin-left: 75%;
  }

  .offset-sm-10 {
    margin-left: 83.3333%;
  }

  .offset-sm-11 {
    margin-left: 91.6667%;
  }
}

@media (width >= 768px) {
  .cl-md {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
  }

  .cl-md-auto {
    flex: none;
    width: auto;
    max-width: 100%;
  }

  .cl-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }

  .cl-md-2 {
    flex: 0 0 16.6667%;
    max-width: 16.6667%;
  }

  .cl-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .cl-md-4 {
    flex: 0 0 33.3333%;
    max-width: 33.3333%;
  }

  .cl-md-5 {
    flex: 0 0 41.6667%;
    max-width: 41.6667%;
  }

  .cl-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .cl-md-7 {
    flex: 0 0 58.3333%;
    max-width: 58.3333%;
  }

  .cl-md-8 {
    flex: 0 0 66.6667%;
    max-width: 66.6667%;
  }

  .cl-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .cl-md-10 {
    flex: 0 0 83.3333%;
    max-width: 83.3333%;
  }

  .cl-md-11 {
    flex: 0 0 91.6667%;
    max-width: 91.6667%;
  }

  .cl-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .offset-md-0 {
    margin-left: 0;
  }

  .offset-md-1 {
    margin-left: 8.33333%;
  }

  .offset-md-2 {
    margin-left: 16.6667%;
  }

  .offset-md-3 {
    margin-left: 25%;
  }

  .offset-md-4 {
    margin-left: 33.3333%;
  }

  .offset-md-5 {
    margin-left: 41.6667%;
  }

  .offset-md-6 {
    margin-left: 50%;
  }

  .offset-md-7 {
    margin-left: 58.3333%;
  }

  .offset-md-8 {
    margin-left: 66.6667%;
  }

  .offset-md-9 {
    margin-left: 75%;
  }

  .offset-md-10 {
    margin-left: 83.3333%;
  }

  .offset-md-11 {
    margin-left: 91.6667%;
  }
}

@media (width >= 992px) {
  .cl-lg {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
  }

  .cl-lg-auto {
    flex: none;
    width: auto;
    max-width: 100%;
  }

  .cl-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }

  .cl-lg-2 {
    flex: 0 0 16.6667%;
    max-width: 16.6667%;
  }

  .cl-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .cl-lg-4 {
    flex: 0 0 33.3333%;
    max-width: 33.3333%;
  }

  .cl-lg-5 {
    flex: 0 0 41.6667%;
    max-width: 41.6667%;
  }

  .cl-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .cl-lg-7 {
    flex: 0 0 58.3333%;
    max-width: 58.3333%;
  }

  .cl-lg-8 {
    flex: 0 0 66.6667%;
    max-width: 66.6667%;
  }

  .cl-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .cl-lg-10 {
    flex: 0 0 83.3333%;
    max-width: 83.3333%;
  }

  .cl-lg-11 {
    flex: 0 0 91.6667%;
    max-width: 91.6667%;
  }

  .cl-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .offset-lg-0 {
    margin-left: 0;
  }

  .offset-lg-1 {
    margin-left: 8.33333%;
  }

  .offset-lg-2 {
    margin-left: 16.6667%;
  }

  .offset-lg-3 {
    margin-left: 25%;
  }

  .offset-lg-4 {
    margin-left: 33.3333%;
  }

  .offset-lg-5 {
    margin-left: 41.6667%;
  }

  .offset-lg-6 {
    margin-left: 50%;
  }

  .offset-lg-7 {
    margin-left: 58.3333%;
  }

  .offset-lg-8 {
    margin-left: 66.6667%;
  }

  .offset-lg-9 {
    margin-left: 75%;
  }

  .offset-lg-10 {
    margin-left: 83.3333%;
  }

  .offset-lg-11 {
    margin-left: 91.6667%;
  }
}

@media (width >= 1200px) {
  .cl-xl {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
  }

  .cl-xl-auto {
    flex: none;
    width: auto;
    max-width: 100%;
  }

  .cl-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }

  .cl-xl-2 {
    flex: 0 0 16.6667%;
    max-width: 16.6667%;
  }

  .cl-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .cl-xl-4 {
    flex: 0 0 33.3333%;
    max-width: 33.3333%;
  }

  .cl-xl-5 {
    flex: 0 0 41.6667%;
    max-width: 41.6667%;
  }

  .cl-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .cl-xl-7 {
    flex: 0 0 58.3333%;
    max-width: 58.3333%;
  }

  .cl-xl-8 {
    flex: 0 0 66.6667%;
    max-width: 66.6667%;
  }

  .cl-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .cl-xl-10 {
    flex: 0 0 83.3333%;
    max-width: 83.3333%;
  }

  .cl-xl-11 {
    flex: 0 0 91.6667%;
    max-width: 91.6667%;
  }

  .cl-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .offset-xl-0 {
    margin-left: 0;
  }

  .offset-xl-1 {
    margin-left: 8.33333%;
  }

  .offset-xl-2 {
    margin-left: 16.6667%;
  }

  .offset-xl-3 {
    margin-left: 25%;
  }

  .offset-xl-4 {
    margin-left: 33.3333%;
  }

  .offset-xl-5 {
    margin-left: 41.6667%;
  }

  .offset-xl-6 {
    margin-left: 50%;
  }

  .offset-xl-7 {
    margin-left: 58.3333%;
  }

  .offset-xl-8 {
    margin-left: 66.6667%;
  }

  .offset-xl-9 {
    margin-left: 75%;
  }

  .offset-xl-10 {
    margin-left: 83.3333%;
  }

  .offset-xl-11 {
    margin-left: 91.6667%;
  }
}

#home-banner {
  border-top: 4px solid var(--fm-lightblue);
  border-bottom: 15px solid var(--fm-lightblue);
  height: 700px;
  position: relative;
  overflow: hidden;
}

#first-section {
  box-sizing: border-box;
  z-index: 2;
  background-image: linear-gradient(#005598, #418fc3);
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 55%;
  padding: 0 10%;
  display: flex;
  top: 0;
  left: 0;
}

#home-banner p a {
  color: #fff;
}

#home-banner .fleming-f {
  opacity: .035;
  height: 100%;
  animation: 2s slide-in-f;
  position: fixed;
  top: -130px;
  left: 0;
}

@keyframes slide-in-f {
  0% {
    transform: translateX(100px);
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes slide-in-right {
  0% {
    transform: translateX(50px);
  }

  100% {
    transform: translateX(0);
  }
}

#first-section h1 {
  letter-spacing: 1.5px;
  margin: 0;
  font-size: 28px;
}

#first-section h2 {
  margin: 15px 0 12px 2px;
  font-size: 22px;
}

#first-section h1, #first-section h2, #first-section p {
  color: #fff;
}

#first-section p {
  margin: 0 0 0 5px;
  font-size: 14px;
  line-height: 1.6em;
}

#first-section .cta-link {
  margin: 15px 0 20px;
}

.slash {
  background-color: var(--fm-lightblue);
  z-index: 1;
  opacity: .8;
  width: 100%;
  height: calc(55% + 15px);
  left: 0;
}

#home-banner .clip {
  clip-path: polygon(0 0, 100% 0, 100% 90%, 0% 100%);
}

#second-section {
  width: 100%;
  height: 50%;
  top: 50%;
  left: 0;
}

#home-banner .tns-outer, #home-banner .tns-inner, [id^="home-slider"] {
  height: 100%;
}

#home-banner #home-slider > .tns-item {
  height: 100%;
  transition-duration: 1.5s;
  animation-duration: 1.5s;
}

#home-slider .main-img {
  object-fit: cover;
  height: 100%;
}

#home-slider .main-img, #home-slider .product-img {
  transition: transform .3s;
}

#home-slider a:hover .main-img {
  transform: scale(1.05);
}

#home-slider .product-img {
  max-width: 33%;
}

#home-slider a:hover .product-img {
  transform: translateY(-10px);
}

#home-banner .tns-nav {
  width: 100%;
  position: absolute;
  bottom: 5px;
}

#home-banner .tns-nav > [aria-controls] {
  width: 12px;
  height: 12px;
  box-shadow: 0 1px 3px #0000004d;
}

#home-banner .tns-nav > .tns-nav-active {
  background-color: #fff;
}

#about-us-img {
  box-sizing: border-box;
  float: right;
  border: 5px solid #fff;
  border-top-left-radius: 35px;
  border-bottom-right-radius: 35px;
  width: 600px;
  max-width: 100%;
  height: 355px;
  margin: 5px 0 25px 40px;
}

#about-us-img:hover .bg-img {
  transform: scale(1.1);
}

.value-prop {
  text-align: center;
  border-bottom: 5px solid var(--fm-lightblue);
  background-color: #f4f9fd;
  min-height: 320px;
  margin: 55px 10px 20px;
  padding: 40px 25px;
  transition: background-color .3s;
  display: block;
  text-decoration: none !important;
}

.value-prop:hover {
  background-color: #e9f3fb;
}

.value-prop:hover img {
  transform: scale(1.1);
}

.value-prop:hover .cta-link {
  background-color: var(--fm-blue);
  transform: translateY(-5px);
}

.value-prop-icon {
  box-sizing: border-box;
  background-color: #fff;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  width: 110px;
  height: 110px;
  display: flex;
  top: -55px;
  left: 50%;
  transform: translateX(-50%);
}

.value-prop-icon img {
  width: 55px;
  transition: all .3s;
}

.value-prop h3 {
  text-transform: unset;
  color: var(--fm-lightblue);
  margin-top: 40px;
  font-size: 22px;
  font-weight: 700;
}

.value-prop p {
  color: #666;
  min-height: 150px;
  font-size: 18px;
}

.value-prop .cta-link {
  background-color: var(--fm-lightblue);
  color: #fff;
  border: none;
  margin: 0 auto;
}

#product-slider-ow .tns-nav > .tns-nav-active {
  background: var(--fm-lightblue);
}

#product-slider-ow .tns-controls {
  justify-content: space-between;
  margin-top: -25px;
  display: flex;
}

#product-slider-ow .tns-controls button {
  color: var(--fm-lightblue);
  background: none;
  border: none;
  outline: none;
  font-size: 30px;
}

#product-slider a h3 {
  color: var(--fm-blue);
  text-transform: unset;
  padding-right: 10px;
  font-size: 18px;
  font-weight: 700;
}

#product-slider a:hover {
  text-decoration: none;
}

#product-slider a:hover img {
  transform: scale(1.05);
}

#product-slider .img-wrap {
  overflow: hidden;
}

#product-slider a img {
  transition: all .3s;
}

#product-slider a p {
  color: #666;
  padding: 0 10px 30px 0;
  font-size: 16px;
}

.condition {
  flex-direction: column;
  align-items: center;
  padding: 15px;
  display: flex;
}

.condition:hover {
  text-decoration: none;
}

.condition:hover .condition-icon {
  background-color: var(--fm-blue);
  border-color: #eee;
}

.condition:hover .condition-icon img {
  transform: scale(1.05);
}

.condition:hover .cta-link {
  background-color: var(--fm-blue);
  color: #fff;
  transform: translateY(-5px);
}

.condition-icon {
  background-color: #37a2e1;
  border: 15px solid #a6e2f5;
  border-radius: 50%;
  width: 150px;
  height: 150px;
  transition: all .3s;
}

.condition-icon img {
  max-width: 65%;
  max-height: 65%;
  transition: all .3s;
}

.condition h3 {
  color: var(--fm-lightblue);
  margin-bottom: 0;
}

.condition dfn {
  font-style: normal;
}

#news-slider article h3 {
  margin-bottom: 10px;
  font-size: 20px;
}

#news-slider article p {
  color: #666;
  font-size: 16px;
}

#news-slider article p strong {
  color: var(--fm-blue);
}

#news-slider .wrap {
  border-radius: 4px;
  height: 300px;
  overflow: hidden;
}

#news-slider .overlay {
  opacity: 0;
  background-color: #000;
  transition: opacity .3s;
}

#news-slider article:hover .bg-img {
  transform: scale(1.1);
}

#news-slider article:hover .overlay {
  opacity: .2;
}

#news-slider .cta-link {
  opacity: 0;
  cursor: pointer;
  margin: 0 auto;
  transition: all .3s;
  top: 50%;
}

#news-slider article:hover .cta-link {
  opacity: .9;
  margin-top: -30px;
}

@media (width >= 768px) {
  #home-banner {
    height: 550px;
  }

  #home-banner .fleming-f {
    top: 0;
    left: 5%;
  }

  #first-section {
    width: 50%;
    height: 100%;
    padding: 0 8%;
  }

  #first-section h1 {
    font-size: 32px;
  }

  #first-section h2 {
    font-size: 24px;
  }

  #first-section p {
    font-size: 15px;
  }

  .slash {
    width: calc(50% + 30px);
    height: 100%;
    animation: 2s slide-in-right;
  }

  #home-banner .clip {
    clip-path: polygon(0 0, 100% 0, calc(100% - 100px) 100%, 0% 100%);
  }

  #second-section {
    width: calc(50% + 100px);
    height: 100%;
    animation: 2s slide-in-right;
    top: 0;
    left: calc(50% - 100px);
  }
}

@media (width >= 992px) {
  #home-banner {
    height: 600px;
  }

  #first-section {
    padding: 0 10%;
  }

  #first-section h1 {
    font-size: 36px;
  }

  #first-section h2 {
    font-size: 26px;
  }

  #first-section p {
    font-size: 16px;
  }

  #first-section .cta-link {
    margin: 20px 0 25px;
  }
}

@media (width >= 1200px) {
  #home-banner {
    height: 700px;
  }

  #first-section {
    padding: 0 12.5%;
  }

  #first-section h1 {
    font-size: 42px;
  }

  #first-section p {
    font-size: 18px;
  }

  #first-section .cta-link {
    margin: 25px 0 40px;
  }
}

@media (width >= 2000px) {
  #home-banner {
    height: 800px;
  }
}

.resources .heading {
  align-items: center;
  margin-top: 50px;
  display: flex;
}

.resources .heading i {
  width: 40px;
}

.resources .heading i, .resources .heading h2 {
  color: var(--fm-blue);
  font-size: 1.8em;
}

.resources .heading h2 {
  font-weight: 600;
}

.resources h3, .resources .heading h2 {
  text-transform: none;
  letter-spacing: unset;
}

.resources h3 {
  font-size: 1.4em;
}

.resources .accordion-section {
  padding-bottom: 15px;
}

.resources .accordion-header .btn-show-hide {
  border: 3px solid var(--fm-lightblue) !important;
}

.resources .accordion a {
  margin-bottom: 5px;
  font-size: 16px;
  display: block;
}

.news-title.font-title.font-bigger {
  color: var(--fm-blue);
  font-size: 20px;
  font-weight: 500;
  line-height: 25px;
}

.list-news li {
  margin: 40px 0;
}

.list-news-header li {
  margin: 15px 0;
}

.arrow-black:before {
  top: .8em;
}

#newsPage h1 {
  text-transform: capitalize !important;
}

.news-date {
  color: #999;
}

#newsOverviewPage, #newsPage {
  font-size: 16px;
}

#newsPage h2 {
  margin: 30px 0 20px;
  font-size: 20px;
}

.news-item-page figure {
  margin: 0;
  padding: 5px;
}

.news-item-page figcaption {
  text-align: center;
  color: #aaa;
  font-size: 13px;
}

.news-banner {
  box-sizing: border-box;
  border-radius: 2px;
  width: 100%;
  padding: 5px;
  transition: box-shadow .2s;
  box-shadow: 0 0 10px #00000040;
}

.news-banner:hover {
  box-shadow: 0 0 4px #00000040;
}

.news-flex-col {
  flex-direction: row;
  justify-content: space-between;
  display: flex;
}

.news-flex-col a {
  padding: 10px;
}

.news-flex-col a:hover {
  text-decoration: none;
}

.list-news li a {
  display: inline;
}

.list-news .news-date {
  display: block;
}

#newsPage table td {
  padding: 10px;
}

#newsPage table {
  font-size: 16px;
}

.combined-description {
  margin: 20px 0;
  font-size: 18px;
  line-height: 1.6;
}

.combined-description h2 {
  text-transform: none;
  color: var(--fm-blue);
  margin-bottom: 12px;
  font-size: 24px;
  font-weight: 700;
}

.product-title, .productlist-list-page h2 {
  color: var(--fm-blue);
}

.productlist-list-page .column-facets, .productlist-search-page .column-facets {
  display: none;
}

.productlist-list-page .combined-header {
  margin-top: 0;
}

.l-products-item, .l-products-item:after, .l-products-item:before, .list-news li, .list-reviews li {
  border-color: #fff;
}

.product-list-intro {
  margin-bottom: 30px;
}

.product-list-intro .heading-lowercase {
  margin-bottom: 0;
  font-size: 26px;
}

.details-info h1 {
  padding-bottom: .3em;
  line-height: 1.6 !important;
}

[itemprop="productID"], .product-id-value {
  text-transform: uppercase;
}

.has-aside {
  border: none;
  width: 100%;
}

.product-details-page aside {
  display: block;
}

.product-details-page .description {
  font-size: 15px;
  line-height: 1.6;
}

#productPage .price-note {
  background-color: #f8f8f8;
  border: 1px solid #eee;
  border-radius: 5px;
  padding: 10px 20px;
}

#productPage .hyp-print-preview {
  color: var(--fm-blue) !important;
}

.uk-site #productPage .after-prices {
  display: none;
}

.uk-site .lbl-price sup .vat {
  color: #999;
  margin-left: 10px;
  font-size: 13px;
}

.product-details-page .attachments .gvi-name-value .name {
  width: 100%;
}

#productPage div[itemprop="description"]:after {
  content: "...";
}

#phx-header {
  box-sizing: border-box;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

#phx-header * {
  box-sizing: border-box;
}

#phx-header .phx-logo {
  width: 450px;
  max-width: 100%;
  margin: 40px 0;
}

#phx-header .heading-wrap {
  position: relative;
}

#phx-header .heading-wrap h2 {
  opacity: .6;
  text-align: center;
  background-color: #fff;
  width: 100%;
  padding: 10px;
  font-size: 25px;
  position: absolute;
  bottom: 30px;
}

.categories.phx .category-hover-btn {
  background-color: #15284c;
  border-left: 5px solid #cb2245;
}

.categories.phx .border-effect {
  background-color: #cb2245;
}

#whh-banner svg #heart {
  transform-origin: center;
  animation: 1.5s .5s infinite beat;
}

@keyframes beat {
  0% {
    transform: scale(1);
  }

  95% {
    transform: scale(1.03);
  }
}

#whh-slider-wrap {
  margin-top: 45px;
}

#whh-slider-wrap p {
  text-align: center;
  cursor: grab;
  padding: 15px;
  font-size: 18px;
}

.tns-nav {
  text-align: center;
}

.tns-nav > [aria-controls] {
  background: #ddd;
  border: 0;
  border-radius: 50%;
  width: 9px;
  height: 9px;
  margin: 0 5px;
  padding: 0;
}

#whh-slider-wrap .tns-nav > .tns-nav-active {
  background: var(--ls-blue);
}

.tns-nav > [aria-controls]:focus {
  outline: none;
}

@media (width >= 768px) {
  #phx-header .heading-wrap h2 {
    font-size: 45px;
  }

  .categories.phx .category-link {
    flex-basis: 49%;
  }
}

@media (width >= 992px) {
  #phx-header .heading-wrap h2 {
    font-size: 65px;
  }
}

.ls-bg-gradient {
  background-image: linear-gradient(315deg, #d2e7f9, #f9f9f9, #eaf4fc);
  background-repeat: no-repeat;
}

#tlp-banner {
  text-align: center;
}

#tlp-banner img {
  width: 350px;
  max-width: 85%;
  margin-bottom: 40px;
}

#tlp-banner h1 {
  margin-bottom: 40px;
  font-size: 50px;
}

#tlp-banner p {
  color: #bbb;
  max-width: 700px;
  margin: 0 auto;
  font-size: 30px;
  font-weight: 700;
  line-height: 1.5em;
}

.h-lifesense {
  text-transform: unset;
  color: var(--ls-blue);
  width: 100%;
  font-weight: 700;
  line-height: 1.5em;
}

h2.h-lifesense {
  margin-bottom: 20px;
  font-size: 30px;
}

.product-link-card:hover {
  text-decoration: none;
}

.modal {
  z-index: 99999;
  opacity: 0;
  pointer-events: none;
  background-color: #000000b3;
  transition: opacity .3s ease-in;
  position: fixed;
  inset: 0;
}

.modal:target {
  opacity: 1;
  pointer-events: auto;
}

.modal-wrap {
  background-color: #fff;
  border-radius: 25px;
  width: 500px;
  max-width: 90%;
  height: 500px;
  max-height: 60%;
  position: absolute;
  top: 50%;
  left: 50%;
  overflow: hidden;
  transform: translate(-50%, -50%);
}

.modal-body {
  width: 90%;
  height: 80%;
  position: relative;
  top: 10%;
  left: 5%;
  overflow-y: scroll;
}

.modal-close {
  font-size: 30px;
  text-decoration: none;
  top: 10px;
  right: 5%;
}

.modal-close:hover {
  color: var(--fm-lightblue);
}

a.hyp-account.goto-returnorders, a.hyp-account.goto-returnreceipts {
  display: none;
}

.card-acc-type {
  background-image: linear-gradient(90deg, #f4f9fd, #0000);
  border: 3px solid #6fb9e1;
  border-radius: 30px;
  min-height: 375px;
  padding: 35px;
  position: relative;
}

.card-acc-type .num {
  color: #fff;
  background-color: #005598;
  border: 3px solid #fff;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  font-size: 20px;
  font-weight: 700;
  display: flex;
  position: absolute;
  top: -30px;
  left: 0;
}

.card-acc-type h3 {
  color: #005598;
  text-transform: unset;
  letter-spacing: unset;
  margin-top: 20px;
  font-size: 18px;
  font-weight: 700;
}

.card-acc-type .acc-icon {
  color: #6fb9e1;
  background-color: #fff;
  border-radius: 20%;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  margin-bottom: 8px;
  margin-right: 8px;
  display: inline-flex;
  box-shadow: 2px 2px #ddd;
}

.card-acc-type p {
  margin: 5px 0;
  font-size: 15px;
  line-height: 1.4em;
}

.card-acc-type .fa-angle-double-right {
  color: #6fb9e1;
  margin-right: 5px;
}

.card-acc-type .fa-check-circle {
  color: #0eae0e;
  margin-left: 5px;
}

.card-acc-type .cta-link {
  position: absolute;
  bottom: 130px;
  left: 50%;
  transform: translateX(-50%);
}

.card-acc-type img {
  transition: all .3s;
  position: absolute;
  bottom: -45px;
  left: 50%;
  transform: translateX(-50%);
}

.card-acc-type a:hover + img {
  bottom: -35px;
}

#myAccountPage .account-top-actions {
  display: none;
}

#loginPage, #forgotPasswordPage, #createAccountPage {
  font-size: 16px;
}

#loginPage h3, #createAccountPage h3 {
  text-transform: none;
}

.gvi-totals-order-history:after {
  content: "";
}

#loginPage .semicolumn {
  width: 100%;
}

.product-to-return .row-comment {
  display: none;
}

.btn-profile-container {
  margin: 2px 0 5px;
}

.btn-profile-wrap {
  flex-flow: wrap;
  justify-content: space-between;
  display: flex;
}

.btn-profile {
  flex-basis: 100%;
  margin-bottom: 2px;
}

.hyp-print-preview {
  color: #fff !important;
}

.column.border-simple-left {
  border: none;
}

.profile-page-cnt .history-details:after {
  background-image: url("https://www.flemingmedical.ie/content/files/images/invoice-footer-ireland-v1.svg");
  min-height: 120px;
  margin: 10px 0;
  padding: 0;
}

@media screen and (width >= 375px) {
  .btn-profile {
    flex-basis: 49%;
  }
}

@media screen and (width >= 475px) {
  .btn-profile {
    flex-basis: 49.5%;
  }
}

@media screen and (width >= 768px) {
  .btn-profile {
    flex-basis: 32.5%;
  }
}

@media screen and (width >= 1024px) {
  .btn-profile-container {
    margin: 5px 0;
  }

  .btn-profile {
    flex-basis: 19.5%;
  }
}

.profile-row {
  flex-flow: wrap;
  justify-content: space-between;
  margin-bottom: 40px;
  display: flex;
}

.profile-card {
  cursor: pointer;
  border-radius: 5px;
  flex-basis: 100%;
  height: 420px;
  margin-top: 20px;
  position: relative;
  overflow: hidden;
}

.profile-card:hover img {
  transform: scale(1.1);
}

.profile-card img, .profile-card .content {
  position: absolute;
}

.profile-card img {
  object-fit: cover;
  width: 100%;
  height: 70%;
  transition: transform .4s;
  top: 0;
  left: 0;
}

.profile-card .content {
  box-sizing: border-box;
  border-top: 5px solid var(--fm-lightblue);
  background-color: #fff;
  background-image: url("https://www.flemingmedical.ie/content/files/images/banner-images/vector-bg-img-grey-v2.jpg");
  background-attachment: fixed;
  width: 100%;
  height: 420px;
  padding: 0 10px;
  transition: top .4s;
  top: 70%;
}

.profile-card.expanded .content {
  top: 0;
}

.profile-card .toggle {
  color: var(--fm-blue);
  font-size: 20px;
  transition: transform .4s;
  position: absolute;
  bottom: 10px;
  right: 10px;
}

.profile-card.expanded .toggle {
  transform: rotate(135deg);
}

.profile-card .name {
  letter-spacing: 2px;
  margin-top: 30px;
  font-size: 20px;
}

.profile-card .title {
  color: var(--fm-lightblue);
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-bottom: 10px;
}

.profile-card .name, .profile-card .title {
  text-align: center;
  font-weight: 700;
}

.profile-card .info {
  opacity: 0;
  font-size: 15px;
  transition: opacity .4s;
}

.profile-card.expanded .info {
  opacity: 1;
}

.profile-card .info ul {
  margin-block-start: 0;
  padding-inline-start: 25px;
}

@media (width >= 550px) {
  .profile-card {
    flex-basis: 48%;
  }
}

@media (width >= 769px) {
  .profile-card {
    flex-basis: 32%;
  }
}

.tns-outer {
  padding: 0 !important;
}

.tns-outer [hidden] {
  display: none !important;
}

.tns-outer [aria-controls], .tns-outer [data-action] {
  cursor: pointer;
}

.tns-slider {
  transition: all;
}

.tns-slider > .tns-item {
  box-sizing: border-box;
}

.tns-horizontal.tns-subpixel {
  white-space: nowrap;
}

.tns-horizontal.tns-subpixel > .tns-item {
  vertical-align: top;
  white-space: normal;
  display: inline-block;
}

.tns-horizontal.tns-no-subpixel:after {
  content: "";
  clear: both;
  display: table;
}

.tns-horizontal.tns-no-subpixel > .tns-item {
  float: left;
}

.tns-horizontal.tns-carousel.tns-no-subpixel > .tns-item {
  margin-right: -100%;
}

.tns-no-calc {
  position: relative;
  left: 0;
}

.tns-gallery {
  min-height: 1px;
  position: relative;
  left: 0;
}

.tns-gallery > .tns-item {
  transition: transform, opacity;
  position: absolute;
  left: -100%;
}

.tns-gallery > .tns-slide-active {
  position: relative;
  left: auto !important;
}

.tns-gallery > .tns-moving {
  transition: all .25s;
}

.tns-autowidth {
  display: inline-block;
}

.tns-lazy-img {
  opacity: .6;
  transition: opacity .6s;
}

.tns-lazy-img.tns-complete {
  opacity: 1;
}

.tns-ah {
  transition: height;
}

.tns-ovh {
  overflow: hidden;
}

.tns-visually-hidden {
  position: absolute;
  left: -10000em;
}

.tns-transparent {
  opacity: 0;
  visibility: hidden;
}

.tns-fadeIn {
  opacity: 1;
  filter: alpha(opacity= 100);
  z-index: 0;
}

.tns-normal, .tns-fadeOut {
  opacity: 0;
  filter: alpha(opacity= 0);
  z-index: -1;
}

.tns-vpfix {
  white-space: nowrap;
}

.tns-vpfix > div, .tns-vpfix > li {
  display: inline-block;
}

.tns-t-subp2 {
  width: 310px;
  height: 10px;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
}

.tns-t-ct {
  width: -webkit-calc(100% * 70 / 3);
  width: -moz-calc(100% * 70 / 3);
  width: 2333.33%;
  position: absolute;
  right: 0;
}

.tns-t-ct:after {
  content: "";
  clear: both;
  display: table;
}

.tns-t-ct > div {
  width: -webkit-calc(100% / 70);
  width: -moz-calc(100% / 70);
  float: left;
  width: 1.42857%;
  height: 10px;
}

.tns-controls {
  justify-content: space-between;
  margin-top: -25px;
  display: flex;
}

.tns-controls button {
  color: var(--fm-lightblue);
  background: none;
  border: none;
  font-size: 30px;
  outline: none !important;
}

.tns-nav > [aria-controls].tns-nav-active {
  background-color: var(--fm-lightblue);
}

.product-slider a h3 {
  color: var(--fm-blue);
  text-transform: unset;
  padding-right: 10px;
  font-size: 18px;
  font-weight: 700;
}

.product-slider a:hover {
  text-decoration: none;
}

.product-slider a:hover img {
  transform: scale(1.05);
}

.product-slider .img-wrap {
  overflow: hidden;
}

.product-slider a img {
  transition: all .3s;
}

.product-slider a p {
  color: #666;
  padding: 0 10px 30px 0;
  font-size: 16px;
}

.quote-slider {
  margin-bottom: 20px;
}

.quote-slider img {
  background-color: #fff;
  border-radius: 50%;
  padding: 5px;
}

.quote-slider p {
  margin: 0 0 0 40px;
  font-weight: 700;
}

.quote-slider .fa-quote-left, .quote-slider .fa-quote-right {
  z-index: -1;
  opacity: .2;
  font-size: 40px;
}

.quote-slider .fa-quote-left {
  top: -16px;
  left: -50px;
  transform: scale(1, -1);
}

.quote-slider .fa-quote-right {
  bottom: -12px;
  right: -48px;
}

.btn-femsense {
  color: #d6749a;
  border: 2px solid #d6749a;
}

.btn-femsense:hover {
  background-color: #d6749a;
}

.btn-sttemp {
  color: #55a4b1;
  border: 2px solid #55a4b1;
}

.btn-sttemp:hover {
  background-color: #55a4b1;
}

#stsense-menu {
  justify-content: space-around;
  align-items: center;
  padding: 10px 0;
}

#stsense-menu #stsense-logo {
  display: none;
}

#stsense-menu .btn-stsense {
  width: unset;
  text-align: center;
  letter-spacing: unset;
  padding: 6px;
  font-size: 13px;
}

#stsense-menu .btn-stsense i {
  margin-right: 5px;
}

#stsense-menu > div {
  flex-wrap: wrap;
  align-items: center;
  display: flex;
}

#stsense-header {
  padding-top: 40px;
  padding-bottom: 70px;
  position: relative;
}

#stsense-header .center > div {
  text-align: center;
  color: #fff;
  z-index: 1;
  justify-content: center;
}

#stsense-header .rw > div:nth-child(2) {
  order: -1;
}

#stsense-banner-logo {
  width: 330px;
  margin-top: 12px;
}

#stsense-header h1 {
  color: #fff;
  margin-top: 20px;
  font-size: 28px;
  font-weight: bold;
}

#stsense-header p {
  letter-spacing: 1px;
  font-size: 18px;
  line-height: 1.4em;
}

#stsense-header .btn-femsense {
  background-color: #fff;
  border: none;
  display: inline;
}

#stsense-header .btn-femsense:hover {
  background-color: #be4b91;
}

#stsense-header #steadysense-chip {
  opacity: 0;
  animation: 6s 2s infinite opacity-fade;
}

@keyframes opacity-fade {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

#stsense-header #temp-curve {
  z-index: 0;
  width: 200%;
  margin-left: -50%;
  position: absolute;
  top: 30%;
}

#stsense-header #temp-curve path {
  stroke: #fff;
  stroke-width: 6px;
  fill: none;
  opacity: 0;
  stroke-dasharray: 2000;
  stroke-dashoffset: 2000px;
  animation: 6s linear infinite forwards dash;
}

@keyframes dash {
  0% {
    stroke-dashoffset: 2000px;
    opacity: 0;
  }

  75% {
    opacity: .2;
  }

  100% {
    stroke-dashoffset: 4000px;
    opacity: 0;
  }
}

.femsense-txt-gradient {
  -webkit-text-fill-color: transparent;
  background-image: linear-gradient(90deg, #be4b91, #fabe96);
  -webkit-background-clip: text;
}

.sttemp-txt-gradient {
  color: #008fdf;
  -webkit-text-fill-color: transparent;
  background: -webkit-linear-gradient(0deg, #008fdf, #40ebab 80%);
  -webkit-background-clip: text;
}

.stsense-heading {
  text-transform: none;
  font-weight: bold;
  line-height: 1.25em;
}

h2.stsense-heading {
  margin-bottom: 25px;
  font-size: 30px;
}

h3.stsense-heading {
  margin-bottom: 20px;
  font-size: 22px;
}

#femsense-app-slider {
  width: 280px;
  height: 580px;
}

#sttemp-app-slider {
  width: 280px;
  height: 550px;
}

#femsense-slider-wrap .swiper-button-prev, #femsense-slider-wrap .swiper-button-next {
  color: #d6749a;
  text-shadow: none;
}

#femsense-slider-wrap .swiper-pagination-bullet-active {
  background-color: #d6749a;
}

#sttemp-slider-wrap .swiper-button-prev, #sttemp-slider-wrap .swiper-button-next {
  color: #008fdf;
  text-shadow: none;
}

#sttemp-slider-wrap .swiper-pagination-bullet-active {
  background-color: #008fdf;
}

#femsense-faq .accordion-header .btn-show-hide {
  background-color: #be4b91 !important;
  border: 3px solid #fabe96 !important;
}

#femsense-faq p, #femsense-faq ul {
  font-size: 16px;
}

#femsense-faq .accordion a {
  display: inline;
}

@media (width >= 390px) {
  #stsense-menu .btn-stsense {
    font-size: 15px;
  }

  #stsense-header {
    padding-top: 50px;
    padding-bottom: 110px;
  }
}

@media (width >= 576px) {
  #stsense-header {
    padding-bottom: 150px;
  }
}

@media (width >= 768px) {
  #stsense-header .rw > div:nth-child(2) {
    order: 0;
  }

  #stsense-header #temp-curve {
    width: 120%;
    margin-left: -10%;
  }

  #stsense-header #temp-curve path {
    stroke-width: 2px;
  }
}

@media (width >= 992px) {
  #stsense-header {
    padding-bottom: 200px;
  }

  #stsense-header h1 {
    font-size: 32px;
  }

  #stsense-header p {
    padding: 0 25px;
    font-size: 20px;
  }
}

@media (width >= 1200px) {
  #stsense-menu #stsense-logo {
    display: flex;
  }

  #stsense-header #temp-curve {
    width: 102%;
    margin-left: -1%;
    top: 20%;
  }
}

@media (width >= 2000px) {
  #stsense-header .center {
    padding-bottom: 50px;
  }

  #stsense-header #temp-curve {
    top: 5%;
  }
}
/*# sourceMappingURL=index.b7d4e9ae.css.map */
