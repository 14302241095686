.btn-femsense {
    color: #d6749a;
    border: 2px solid #d6749a;
}

.btn-femsense:hover {
    background-color: #d6749a;
}

.btn-sttemp {
    color: #55a4b1;
    border: 2px solid #55a4b1;
}

.btn-sttemp:hover {
    background-color: #55a4b1;
}

#stsense-menu {
    align-items: center;
    justify-content: space-around;
    padding: 10px 0;
}

#stsense-menu #stsense-logo {
    display: none;
}

#stsense-menu .btn-stsense {
    width: unset;
    padding: 6px 6px;
    font-size: 13px;
    text-align: center;
    letter-spacing: unset;
}

#stsense-menu .btn-stsense i {
    margin-right: 5px;
}

#stsense-menu>div {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

#stsense-header {
    padding-top: 40px;
    padding-bottom: 70px;
    position: relative;
}

#stsense-header .center>div {
    justify-content: center;
    text-align: center;
    color: #fff;
    z-index: 1;
}

#stsense-header .rw>div:nth-child(2) {
    order: -1;
}

#stsense-banner-logo {
    margin-top: 12px;
    width: 330px;
}

#stsense-header h1 {
    font-weight: bold;
    font-size: 28px;
    color: #fff;
    margin-top: 20px;
}

#stsense-header p {
    line-height: 1.4em;
    letter-spacing: 1px;
    font-size: 18px;
}

#stsense-header .btn-femsense {
    display: inline;
    background-color: #fff;
    border: none;
}

#stsense-header .btn-femsense:hover {
    background-color: #be4b91;
}

#stsense-header #steadysense-chip {
    opacity: 0;
    animation: opacity-fade 6s 2s infinite;
}

@keyframes opacity-fade {
    0% {
        opacity: 0;
    }

    50% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

#stsense-header #temp-curve {
    position: absolute;
    top: 30%;
    z-index: 0;
    width: 200%;
    margin-left: -50%;
}

#stsense-header #temp-curve path {
    stroke: #ffffff;
    stroke-width: 6;
    fill: none;
    opacity: 0;
    stroke-dasharray: 2000;
    stroke-dashoffset: 2000;
    animation: dash 6s linear forwards infinite;
}

@keyframes dash {
    0% {
        stroke-dashoffset: 2000;
        opacity: 0;
    }

    75% {
        opacity: 0.2;
    }

    100% {
        stroke-dashoffset: 4000;
        opacity: 0;
    }
}

.femsense-txt-gradient {
    background-image: linear-gradient(90deg, #be4b91, #fabe96);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.sttemp-txt-gradient {
    color: #008fdf;
    background: -webkit-linear-gradient(0, #008fdf, #40ebab 80%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.stsense-heading {
    text-transform: none;
    font-weight: bold;
    line-height: 1.25em;
}

h2.stsense-heading {
    font-size: 30px;
    margin-bottom: 25px;
}

h3.stsense-heading {
    font-size: 22px;
    margin-bottom: 20px;
}

#femsense-app-slider {
    width: 280px;
    height: 580px;
}

#sttemp-app-slider {
    width: 280px;
    height: 550px;
}

#femsense-slider-wrap .swiper-button-prev,
#femsense-slider-wrap .swiper-button-next {
    color: #d6749a;
    text-shadow: none;
}

#femsense-slider-wrap .swiper-pagination-bullet-active {
    background-color: #d6749a;
}

#sttemp-slider-wrap .swiper-button-prev,
#sttemp-slider-wrap .swiper-button-next {
    color: #008fdf;
    text-shadow: none;
}

#sttemp-slider-wrap .swiper-pagination-bullet-active {
    background-color: #008fdf;
}

#femsense-faq .accordion-header .btn-show-hide {
    background-color: #be4b91 !important;
    border: 3px solid #fabe96 !important;
}

#femsense-faq p,
#femsense-faq ul {
    font-size: 16px;
}

#femsense-faq .accordion a {
    display: inline;
}

@media (min-width: 390px) {
    #stsense-menu .btn-stsense {
        font-size: 15px;
    }

    #stsense-header {
        padding-top: 50px;
        padding-bottom: 110px;
    }
}

@media (min-width: 576px) {
    #stsense-header {
        padding-bottom: 150px;
    }
}

@media (min-width: 768px) {
    #stsense-header .rw>div:nth-child(2) {
        order: 0;
    }

    #stsense-header #temp-curve {
        width: 120%;
        margin-left: -10%;
    }

    #stsense-header #temp-curve path {
        stroke-width: 2;
    }
}

@media (min-width: 992px) {
    #stsense-header {
        padding-bottom: 200px;
    }

    #stsense-header h1 {
        font-size: 32px;
    }

    #stsense-header p {
        font-size: 20px;
        padding: 0 25px;
    }
}

@media (min-width: 1200px) {
    #stsense-menu #stsense-logo {
        display: flex;
    }

    #stsense-header #temp-curve {
        top: 20%;
        width: 102%;
        margin-left: -1%;
    }
}

@media (min-width: 2000px) {
    #stsense-header .center {
        padding-bottom: 50px;
    }

    #stsense-header #temp-curve {
        top: 5%;
    }
}