/* Site-wide general purpose CSS and helper classes */
:root {
    --fm-blue: #005598;
    --fm-lightblue: #6fb9e1;
    --fm-blue-trans: #005598bb;
    --fm-lightblue-trans: #6fb9e1bb;
    --ls-blue: #00a3e0;
    --md-red: #da291c;
}

html {
    scroll-behavior: smooth;
}

body {
    color: #666;
}

/*Centre Nav Bar without Affecting Dropdown*/
.nav-main{
    margin-left: 4%;
}

/*Ben Frost Edits - 19.08.24*/
div[data-id="SHL753"] .prices {
    display: none;
}

.overlay {
    opacity: 0
}


.ls-blue {
    color: var(--ls-blue);
}

.w-100 {
    width: 100% !important;
}

.h-100 {
    height: 100% !important;
}
/*Ben Frost Edits - 19.08.24*/

h1,
h2,
h3,
h4,
h5,
h6,
a.font-h4,
.nav-item-root .hyp-top-lvl {
    letter-spacing: 1px;
    word-break: keep-all;
}

.nav-item-root .hyp-top-lvl {
    padding: 0 14px;
}

.h1-holder {
    background-color: var(--fm-blue) !important;
    border-bottom: 5px solid var(--fm-lightblue);
    border-radius: 4px;
    margin: 10px 0 0;
    padding: 10px 5px 5px;
    text-align: center;
}

.h1-holder h1 {
    font-size: 1.5em;
    font-weight: 700;
}

.h1-holder h1,
.h1-holder span {
    color: #ffffff;
}

.heading-underline,
.heading-lowercase {
    text-transform: none;
    font-weight: 700;
    line-height: 1.3em;
}

.heading-lowercase {
    margin: 1em 0 1.5em;
}

.heading-underline {
    margin: 30px 0 10px;
}

.heading-underline::after {
    background-color: var(--fm-lightblue);
    content: "";
    display: block;
    width: 30px;
    height: 4px;
    margin: 4px 0 0 1px;
}

.block-padding {
    padding-top: 60px;
    padding-bottom: 60px;
}

.block-anchor {
    position: absolute;
    margin-top: -60px;
}

a.hyp-top-lvl {
    font-weight: 700;
}

a:focus {
    outline: none;
}

a.no-decoration:hover {
    text-decoration: none;
}

.site .content {
    padding-bottom: 50px;
}

.bg-img {
    z-index: 0;
    background-size: cover;
    background-position: center center;
    transition: transform 0.4s;
}

.overlay {
    z-index: 1;
    transition: opacity 0.3s;
}

.bg-img,
.overlay {
    top: 0;
    position: absolute;
    height: 100%;
    width: 100%;
}

.wrap {
    position: relative;
    overflow: hidden;
}

.wrap:hover .bg-img {
    transform: scale(1.1);
}

.wrap .cta-link {
    margin: 0 auto;
    top: 50%;
}

.wrap:hover .cta-link {
    margin-top: -30px;
    opacity: 0.9;
}

.wrap:hover .overlay {
    opacity: 0.2;
}

.bg-grey-rounded {
    padding: 25px;
    border-radius: 25px;
    background-image: linear-gradient(135deg, #dfdfdf, #fafafa);
}

.bg-grey-gradient {
    background-image: linear-gradient(135deg, #ddd, #fafafa, #fff, #fefefe, #ddd);
    background-attachment: fixed;
    background-size: cover;
    border-top: 1px solid #eee;
    border-bottom: 1px solid #eee;
}

.infographic {
    max-width: 500px;
    display: block;
    margin: 50px auto;
    box-shadow: 5px 5px 25px #ccc;
    border-radius: 20px;
}

.video-embed {
    width: 100%;
    height: 200px;
    margin: 20px auto;
}

.gmap-embed {
    width: 100%;
    height: 400px;
    box-sizing: border-box;
    border: 5px solid #aadaff;
    border-left: 5px solid var(--fm-lightblue);
    margin-top: 20px;
}

.mobile-frame {
    border: 15px solid #333;
    border-radius: 40px;
    box-sizing: border-box;
}

.page-padding-bottom {
    padding-bottom: 50px;
}

.cta-link {
    display: block;
    width: fit-content;
    color: var(--fm-blue);
    background-color: #ffffff;
    border: 2px solid var(--fm-blue);
    font-size: 14px;
    padding: 4px 12px;
    border-radius: 20px;
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: 1px;
    transition: all 0.3s;
}

.cta-link:hover {
    text-decoration: none;
    color: #ffffff;
    background-color: var(--fm-blue);
}

.cta-link i {
    margin-right: 10px;
}

.cta-link-red {
    border-color: var(--md-red);
    color: var(--md-red);
}

.cta-link-red:hover {
    background-color: var(--md-red);
}

.link-box {
    display: block;
    text-decoration: none !important;
    padding: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.link-box:hover .bg-img {
    transform: scale(1.1);
}

.lb-container {
    overflow: hidden;
    position: relative;
    height: 400px;
}

.lb-container h3 {
    z-index: 1;
    position: absolute;
    bottom: 0;
    color: white;
    background-color: var(--fm-blue-trans);
    padding: 10px;
    width: 100%;
    box-sizing: border-box;
    font-size: 22px;
    text-align: center;
    border-top: 5px solid var(--fm-lightblue-trans);
}

.lb-container .fa-sign-in-alt,
.lb-container .fa-angle-double-right {
    position: absolute;
    opacity: 0;
    color: #fff;
    text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
    font-size: 90px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin-top: 15px;
    transition: all 0.4s;
}

.lb-container:hover .fa-sign-in-alt,
.lb-container:hover .fa-angle-double-right {
    opacity: 1;
    margin-top: -15px;
}

.lb-container .lb-intro {
    position: absolute;
    box-sizing: border-box;
    width: 100%;
    opacity: 0;
    z-index: 2;
    bottom: -100px;
    text-align: center;
    font-size: 15px;
    padding: 20px 20px 20px 30px;
    color: white;
    background-color: var(--fm-blue-trans);
    border-top: 1px solid white;
    margin: 0 auto;
    transition: all 0.4s;
}

.lb-container:hover .lb-intro {
    opacity: 1;
    bottom: 0;
}

.lb-more {
    display: block;
    margin-top: 10px;
}

.lb-more span {
    display: inline-block;
    padding: 6px 8px;
    background-color: rgba(255, 255, 255, 0.7);
    color: var(--fm-blue);
    transition: all 0.4s;
    border-radius: 3px;
}

.lb-intro:hover .lb-more span {
    background-color: rgba(255, 255, 255, 1);
}

.lb-container .overlay {
    background-image: linear-gradient(to top, transparent, white);
}

.lb-container img {
    position: absolute;
    z-index: 2;
    top: 20px;
    margin-left: 50%;
    transform: translate(-50%, 0);
    transition: all 0.4s;
}

.lb-container:hover img {
    top: 30px;
}

.on-top {
    z-index: 2;
}

.page-banner-container {
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-content: stretch;
    align-items: center;
    background-color: #ddd;
    background-size: cover;
    background-attachment: fixed;
    height: 250px;
}

.fa-ul {
    line-height: 2.2em;
}

.fa-ul .fa-li {
    color: #0eae0e;
    font-size: 24px;
    margin-right: 10px;
}

.btn-app img {
    width: 200px;
    margin-left: 10px;
    max-width: 45%;
}

.site {
    overflow: hidden;
}

.body-text {
    font-size: 16px;
    line-height: 1.6em;
}

.info-block {
    display: flex;
    padding: 20px;
    margin-top: 20px;
    background-color: #f8f8f8;
    border-left: 5px solid var(--fm-lightblue);
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    align-items: center;
}

.info-block-text h2 {
    font-size: 24px;
    text-transform: unset;
    color: var(--fm-blue);
    font-weight: 700;
    margin: 5px 0 10px 0;
}

.info-block i {
    display: none;
    font-size: 70px;
    margin: 0 0 0 15px;
    color: #0069aa40;
    justify-content: center;
    align-items: center;
}

.info-block p {
    font-size: 16px;
    margin: 0;
}

.page-heading {
    background-color: var(--fm-blue);
    margin-top: 20px;
    text-align: center;
    padding: 10px;
    color: #fff;
    font-weight: 600;
    border-bottom: 5px solid var(--fm-lightblue);
    border-radius: 4px;
}

.page-heading i {
    margin-right: 10px;
}

.top-dropdown.language .hyp {
    display: flex;
    padding: 0 35px 0 15px;
    border-left: 5px solid transparent;
}

.top-dropdown.language .hyp.selected {
    border-left-color: #6fb9e155;
}

.language.top-dropdown .summary {
    padding-top: 0;
}

.top-dropdown.language .opener .flag {
    left: 10px;
}

.curved-banner .banner-curve {
    position: absolute;
    bottom: -2px;
    width: 100%;
}

.curved-banner .banner-curve:nth-last-child(2) {
    bottom: 10px;
    opacity: 0.25;
}

.img-resp {
    box-sizing: border-box;
    width: 100%;
    max-width: 100%;
    max-height: 100%;
}

[data-aos] {
    animation-timing-function: ease;
    animation-duration: 1.2s;
}

[data-aos="fade-up"] {
    animation-name: fade-up;
}

[data-aos="fade-down"] {
    animation-name: fade-down;
}

[data-aos="flip-up"] {
    backface-visibility: hidden;
    animation-name: flip-up;
}

@keyframes fade-up {
    0% {
        transform: translate3d(0, 100px, 0);
        opacity: 0;
    }

    33% {
        transform: translate3d(0, 100px, 0);
        opacity: 0;
    }

    100% {
        transform: translate3d(0, 0, 0);
        opacity: 1;
    }
}

@keyframes fade-down {
    0% {
        transform: translate3d(0, -100px, 0);
        opacity: 0;
    }

    33% {
        transform: translate3d(0, -100px, 0);
        opacity: 0;
    }

    100% {
        transform: translate3d(0, 0, 0);
        opacity: 1;
    }
}

@keyframes flip-up {
    0% {
        transform: perspective(2500px) rotateX(-100deg);
        opacity: 0;
    }

    33% {
        transform: perspective(2500px) rotateX(-100deg);
        opacity: 0;
    }

    100% {
        transform: perspective(2500px) rotateX(0);
        opacity: 1;
    }
}

@media (min-width: 390px) {
    .curved-banner .banner-curve:nth-last-child(2) {
        bottom: 15px;
    }
}

@media (min-width: 450px) {
    .video-embed {
        height: 300px;
    }
}

@media (min-width: 500px) {
    .page-banner-container {
        height: 350px;
    }
}

@media (min-width: 576px) {
    .curved-banner .banner-curve:nth-last-child(2) {
        bottom: 20px;
    }
}

@media screen and (min-width: 768px) {
    .video-embed {
        height: 400px;
    }

    .body-text {
        font-size: 18px;
    }

    .info-block p {
        font-size: 18px;
    }

    .info-block i {
        display: flex;
    }

    .gmap-embed {
        height: 500px;
    }

    .cta-link {
        padding: 6px 18px;
        font-size: 16px;
    }

    .flipbook {
        height: 550px;
    }
}

@media screen and (min-width: 992px) {
    .page-banner-container {
        height: 550px;
    }

    .video-embed {
        height: 560px;
    }

    .gmap-embed {
        height: 650px;
    }

    .curved-banner .banner-curve:nth-last-child(2) {
        bottom: 40px;
    }

    .flipbook {
        height: 750px;
    }
}

@media (min-width: 1024px) {
    .nav-item-root .hyp-top-lvl {
        padding: 0 10px;
    }
}

@media (min-width: 1200px) {
    .nav-item-root .hyp-top-lvl {
        padding: 0 13px;
    }
}